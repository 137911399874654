import _, { map } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Form from './Form';

import { getAddressOptions, getDepartmentOptions, getTags } from '../selectors/dropdowns';

import { createAddContact } from '../actions/contact';
import { closePopup } from '../actions/popup';
import { updateOrder } from '../actions/order';

import { BASE_ZINDEX } from '../popup-factory';

class AddContactPopup extends Component {

  constructor(props) {
    super(props);

    this.state = { tags: [] };

    _.bindAll(this, ['onFormRef', 'onAddContact']);
  }

  onFormRef(form) {
    this._form = form;
  }

  onAddContact(values, errors) {
    if (!_.every(errors, (error) => error === null)) {
      return;
    }
    const contact = {};
    values.company_contact_tags = map(values.company_contact_tags, 'label');
    _.forOwn(values, (v, k) => _.set(contact, k, v));
    this.props.onAddContact(contact);
    if(this.props.shop_id && this.props.shop_parent_type) {
      this.props.onAddShopContact(contact);
    }
  }

  render() {
    const {
      addresses,
      departments,
      tags,
      onClosePopup,
      index
    } = this.props;

    const phone_types = ['WORK', 'HOME', 'FAX', 'CELL'].map(pt => ({key: pt, value: pt}));

    const default_department_id = departments[0] ? departments[0].key : null;
    const default_address_id = addresses[0] ? addresses[0].key : null;

    return (
      <div id="replace-header-modal" className="reveal" style={{display: 'block', zIndex: BASE_ZINDEX + index}} aria-labelledby="modalTitle" aria-hidden="true" role="dialog">
        <div className="row">
          <div className="small-12 columns">
            <h3 id="modalTitle">Add Contact</h3>
            <a className="alert button" style={{ position: 'fixed', right: '5rem', top: '1rem' }} onClick={e => onClosePopup()}>Cancel</a>
            <a className="button" style={{position: 'fixed', right: '1rem', top: '1rem' }} onClick={(e) => {e.preventDefault(); this._form && this._form.submit();}}>Save</a>
          </div>
        </div>
        <Form className="row popup-content form"
              ref={this.onFormRef}
              object={{parent_id: this.props.contact.parent_id, parent_type: this.props.contact.parent_type}}
              onSubmit={this.onAddContact}
        >
          <Form.TextInput label="First Name" field="contact_first_name" required={true} />
          <Form.TextInput label="Last Name" field="contact_last_name" required={true} />
          <Form.TextInput label="Position" field="contact_position" />
          <Form.TextInput label="Email" field="contact_email" />
          <Form.Select label="Department"
            withMarginBottom
            field="contact_department_id"
            value={default_department_id}
            options={departments}
            required={true} />
          <Form.Select label="Address"
            withMarginBottom
            field="contact_default_address_id"
            value={default_address_id}
            options={addresses} />
          <div className="row field">
            <div className="small-12 medium-4 columns">
              <strong>Phone</strong>
            </div>
            <Form.Select value="WORK"
              withMarginBottom
              displayLabel={false}
              containerClassName="small-12 medium-8 columns"
              field="phone.0.phone_type"
              options={phone_types}  />
            <Form.TextInput displayLabel={false} containerClassName="small-12 medium-offset-4 medium-6 columns" field="phone.0.phone_number" />
            <Form.TextInput placeholder="ext." displayLabel={false} containerClassName="small-12 medium-2 columns" field="phone.0.phone_extension" />
          </div>
          <Form.AutosuggestTags
            label="Tags" field="company_contact_tags" placeholder="Select Tags"
            value={this.state.tags}
            tags={map(tags)}
          />
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const addresses = getAddressOptions(state, { parent_id: ownProps.account_id });
  const departments = getDepartmentOptions(state);
  const tags = getTags(state);
  return {
    addresses,
    departments,
    tags,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onAddShopContact: contact => dispatch(createAddContact({ ...contact, parent_id: ownProps.shop_id, parent_type: ownProps.shop_parent_type })),
    onAddContact: contact => dispatch(createAddContact(contact)).then(action => {
      dispatch(closePopup());
      if (ownProps.order_id) {
        return dispatch(updateOrder(ownProps.order_id, ownProps.field, ownProps.previous_value, action.payload.contact.contact_id));
      }
    }),
    onClosePopup: () => {
      dispatch(closePopup());
    }
  };
};

const ConnectedAddContactPopup = connect(mapStateToProps, mapDispatchToProps)(AddContactPopup);
export default ConnectedAddContactPopup;

